<template>
    <ValidationObserver ref="validator" slim>
        <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
                v-model="phone"
                :label="$t('src\\views\\user\\authorization.9601')"
                :error-messages="errors[0]"
                type="tel"
                autocomplete="off"
                outlined
                @keyup.enter="confirm"
            />
            <v-btn
                color="primary"
                @click="confirm">{{ $t('src\\views\\user\\authorization.806') }}</v-btn>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        name: 'Authorization',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            phone: '',
        }),
        methods: {
            async confirm() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    await store.dispatch('auth/login', { phone: this.phone });
                    await this.$router.push({ name: 'user-confirmation' });
                }
            },
            async registration() {
                await this.$router.push({ name: 'user-registration' });
            }
        }
    }
</script>

<style lang="scss">
.views-user-authorisation {
    padding: 24px;
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0;
    }
    &__link {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #d80765;
    margin-top: 3px;
    }
    &__sheet {
        position: absolute;
        top: 25%;
        right: 25%;
        display: flex;
    }
}

</style>
